import { render, staticRenderFns } from "./EmpleadosEdit.vue?vue&type=template&id=536595cc&scoped=true&"
import script from "./EmpleadosEdit.vue?vue&type=script&lang=js&"
export * from "./EmpleadosEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "536595cc",
  null
  
)

export default component.exports