<template>

  <b-card>

    <b-form
        class="p-2"
    >
      <!-- Nombres -->
      <b-form-group label="Nombres" label-for="usr-nombres">
        <b-form-input
            id="emp-nombres"
            v-model="empleado.empNombres"
            autofocus
            trim
            maxlength="30"
        />
      </b-form-group>
      <!-- Apellidos -->
      <b-form-group label="Apellidos" label-for="usr-apellidos">
        <b-form-input
            id="emp-apellidos"
            v-model="empleado.empApellidos"
            autofocus
            trim
            maxlength="30"
        />
      </b-form-group>
      <!-- Correo -->
      <b-form-group label="Correo electrónico" label-for="email">
        <b-form-input
            id="emp-email"
            v-model="empleado.empEmail"
            autofocus
            trim
            maxlength="30"
            type="email"
        />
      </b-form-group>

      <!-- Cargo -->
      <b-form-group
          label="Cargo"
          label-for="cargo"
      >
        <v-select
            label="posNombre"
            :reduce="x => x.posId"
            v-model="empleado.posId"
            :options="fetchCargos"
            :clearable="false"

        />

      </b-form-group>
      <!-- Areas -->
      <b-form-group
          label="Areas"
          label-for="areas"
      >
        <v-select
            label="secNombre"
            :reduce="x => x.secId"
            v-model="empleado.secId"
            :options="fetchAreas"
            :clearable="false"
        />

      </b-form-group>
      <!-- Oficina -->
      <b-form-group
          label="Oficina"
          label-for="oficina"
      >
        <v-select
            label="offNombre"
            :reduce="x => x.offId"
            v-model="empleado.offId"
            :options="fetchOficinas()"
            :clearable="false"
        />
      </b-form-group>






      <div class="d-flex align-items-center mt-2">
        <label>Colaborador activo</label>
        <b-form-checkbox v-model="empleado.isActive" switch class="ml-2"/>
      </div>


      <hr>
      <!-- Form Actions -->
      <div class="d-flex mt-2">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            :disabled="isBusy || hasSomeNullEmptyItem"
            @click.prevent="handleSubmit"
        >
          <template v-if="isBusy">
            <b-spinner small/>
            Guardando
          </template>
          <template v-else>
            Guardar
          </template>
        </b-button>
        <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :disabled="isBusy"
            @click="handleDiscard"
        >
          Descartar
        </b-button>
      </div>

    </b-form>

    <validation-errors v-if="errors" :errors="errors"/>

  </b-card>

</template>

<script>

import {
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormRadio,
  BButton,
  BSpinner,
  BFormCheckbox
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

import ValidationErrors from '@/modules/components/ValidationErrors.vue'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'

export default {
  name: 'EmpleadosEdit',
  props: {
    empleadoProp: {
      type: Object,
      required: true,
      default: {}
    }
  },
  components: {
    vSelect,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormRadio,
    BButton,
    BSpinner,
    BFormCheckbox,
    ValidationErrors,
    ToastificationContent,
  },directives: {
    Ripple,
  },
  data() {

    return {
      errors: [],
      isBusy: false,
      showSidebar: false,

      empleado: {
        empNombres: this.empleadoProp.empNombres,
        empApellidos: this.empleadoProp.empApellidos,
        empEmail: this.empleadoProp.empEmail,
        empIdentificacion: this.empleadoProp.empIdentificacion,
        offId: this.empleadoProp.offId,
        posId: this.empleadoProp.posId,
        secId: this.empleadoProp.secId,
        isActive: this.empleadoProp.empEstado == 1 ? true : false
      },


      // 0 admin 1 talento humano 2 jefatura 9 root
      optionsType: [
        { text: 'Talento humano', value: 1 },
        { text: 'Jefatura', value: 2 },
      ],
    }

  },
  computed: {
    ...mapGetters('sga-module',['fetchCargos','fetchAreas','fetchOficinas']),
    // verificar si algun elemento de un objeto es NULL o vacío
    hasSomeNullEmptyItem () {
      const nullable = ( element ) => element === null || element === ''
      return Object.values( this.empleado ).some( nullable )
    },

  },

  methods: {
    ...mapActions('sga-module',['getCargos','getAreas','getOficinas']),
    ...mapActions('empleado-module',['updateEmpleado']),
    // procesar peticion de guardar
    async handleSubmit(){


      try {

        this.errors = []

        this.isBusy = true

        const params = {
          empId: this.empleadoProp.empId,
          empUuid: this.empleadoProp.empUuid,
          empNombres: this.empleado.empNombres,
          empApellidos: this.empleado.empApellidos,
          empEmail: this.empleado.empEmail,
          offId: this.empleado.offId,
          posId: this.empleado.posId,
          secId: this.empleado.secId,
          empEstado: this.empleado.isActive ? 1 : 0
        }

        await this.updateEmpleado( params )

        this.$emit('successEditMode')

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sistema',
            icon: 'CheckIcon',
            text: '¡Empleado actualizado exitosamente!',
            variant: 'success'
          },
        })

        this.showSidebar = false

      } catch ( error ) {
         console.log(error);
        // errores de autenticacion
        if ( error.response.status == 422 ) {

          const  data  = error.response.data.errors

          console.log( "UNXPECTED", error.response.data )

          // errores de validacion
          this.errors = data

        }


      } finally {
        this.isBusy = false
      }

    },

    // procesar peticion de descartar
    handleDiscard(){
      this.$emit('closeEditMode')
    },

    // reset los campos del formulario
    resetForm(){
      Object.keys(this.empleado).forEach( key =>  this.empleado[ key ] = null )
    }

    //
  },

  async created() {
    await this.getCargos()
    await this.getAreas()
    await this.getOficinas()
    console.log("created edit", this.empleado )
  }
}
</script>

<style scoped>

</style>
